// Here you can add other styles
// @media print {
//     body * {
//         visibility:hidden;
//     }
//     #printSection, #printSection * {
//         visibility:visible;
//     }
// }

// .ariston-form-cell {
//     border:1px solid black;
// }

// .ariston-form-head {
//     background-color: rgba($color: #0f00b9, $alpha: 0.04);
//     border:1px solid black;
// }

// .vertical-center {
//     margin: 0;
//     position: relative;
//     top: 50%;
//     -ms-transform: translateY(-50%);
//     transform: translateY(-50%);
//   }

// // Required

// Default variable overrides
// $body-bg: #030303;
// $body-color: #000000;


// Here you can add other styles
.c-sidebar-brand-full {
    width: 70%;
    height: auto;
}

.c-sidebar-brand-icon {
    width: 40%;
    height: auto;
}

header.card-header {
    font-weight: bold;
    text-transform: uppercase;
}

.btn-pill {
    padding-top: 2px;
    padding-bottom: 2px;
}

.c-dark-theme .list-group-item {
    color: rgba(255, 255, 255, 0.87);
    background-color: rgba(255, 255, 255, 0.025);
    border-color: rgba(255, 255, 255, 0.025);
}

.c-avatar {
    color: rgba(255, 255, 255, 0.87);
    background-color: #20b523;
    border-color: #3520B5;
    font-size: 1.2em;
}

.SingleDatePickerInput .DateInput_input__focused {
    border-bottom: 0px;
}

.DateInput_fang {
    top: 33px !important;
}

.SingleDatePickerInput .DateInput_input {
    font-weight: 200;
    font-size: 1rem;
    line-height: inherit;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 4px 8px 2px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0px solid transparent;
    border-left: 0;
    border-radius: 0;
}

.SingleDatePicker_picker {
    top: 44px !important;
}


.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 0.25rem;
}

.list-group-item {
    margin-bottom: 6px;
}

.list-group-item+.list-group-item {
    border-top-width: 1px;
}

// Dashboard

.map-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    margin-bottom: 0rem;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid;
    border-radius: 0.2rem;
    background-color: #fff;
    border-color: #d8dbe0;
}

.map-card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding-top: 0.25rem;
    padding-right: 0.75rem;
    padding-bottom: 0.25rem;
    padding-left: 0.75rem;
}

.bg-gradient-gray {
    background: #213328 !important;
    background: linear-gradient(45deg, #3c4b64 0%, #212333 100%) !important;
    border-color: #212333 !important;
}

// Other

.card {
    border-radius: 0.5rem;
}

.card-header:first-child {
    border-radius: 0.5rem;
}

.progress {
    background-color: #a9a8a8;
}

.c-app .bg-gray {
    background-color: goldenrod;
}

.c-app .bg-green {
    background-color: green;
}

.c-app .form-control {
    background-color: rgba(129, 129, 129, 0.05);
    border-color: rgba(129, 129, 129, 0.5);
}

.marker-cluster-offline {
    background-color: rgba(238, 111, 111, 0.6);
}

#root>div.c-app {
    th input.form-control {
        background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath style='fill: gray' d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat right;
        padding-right: 14px;
    }
}


.p-4.login.card {
    background-color: black;

    h1 {
        color: white;
        text-align: center;
    }

    p {
        color: white;
        text-align: center;
    }

    button.px-0 {
        color: #4799eb;
    }
}

.c-login-brand-icon {
    width: 120px;
    display: block;
    padding-bottom: 2em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(0, 0, 0) inset !important;
    -webkit-text-fill-color: rgb(252, 250, 250) !important;
    -webkit-text-color: rgb(252, 250, 250) !important;
}

.list-group-item-action {
    color: #00274c
}

// Date Picker Dark theme
.c-app.c-dark-theme .SingleDatePickerInput .DateInput_input {
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    color: rgba(255, 255, 255, 0.87) !important;
    background-color: rgba(36, 27, 27, 0) !important;
    border-color: rgba(129, 129, 129, 0.5) !important;
}

.c-app.c-dark-theme .SingleDatePickerInput {
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    color: rgba(255, 255, 255, 0.87) !important;
    background-color: rgba(129, 129, 129, 0.0) !important;
    border-color: rgba(129, 129, 129, 0.5) !important;
}

.c-app.c-dark-theme .DateInput {
    background: #2b2c2a !important;
}

.c-app.c-dark-theme .DateInput_input__focused {
    background: #2b2c2a !important;
}

// Date Picker Light theme
.c-app .SingleDatePickerInput .DateInput_input {
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    color: #5c6873 !important;
    background-color: rgba(129, 129, 129, 0.05) !important;
    border-color: rgba(129, 129, 129, 0.5) !important;
}

.c-app .SingleDatePickerInput {
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    color: #5c6873 !important;
    background-color: rgba(129, 129, 129, 0.05) !important;
    border-color: rgba(129, 129, 129, 0.5) !important;
}
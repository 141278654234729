// Variable overrides
:root {
  --amplify-primary-color: #2c3a53;
  --amplify-primary-contrast: #eeeeee;
  --amplify-primary-shade: #4c5a73;
  --amplify-primary-tint: #ffffff;
}
$enable-rounded: true;
$enable-shadows: true;
$gray-base: #0c0c0c !default;
$primary: #00c84d;
// $gray-100: #ebedef !default;
// $gray-200: #d8dbe0 !default;
// $gray-300: #c4c9d0 !default;
// $gray-400: #b1b7c1 !default;
// $gray-500: #ffffff !default;
// $gray-600: #ffffff !default;
// $gray-700: #ffffff !default;
// $gray-800: #ffffff !default;
// $gray-900: #ffffff !default;